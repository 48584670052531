import { template as template_ae6d0c9b46d44ca5a5124869500b3302 } from "@ember/template-compiler";
import Component from "@glimmer/component";
import DButton from "discourse/components/d-button";
export default class PostMenuAdminButton extends Component {
    static shouldRender(args) {
        return (args.post.canManage || args.post.can_wiki || args.post.canEditStaffNotes);
    }
    static{
        template_ae6d0c9b46d44ca5a5124869500b3302(`
    <DButton
      class="post-action-menu__admin show-post-admin-menu"
      ...attributes
      @action={{@buttonActions.openAdminMenu}}
      @forwardEvent={{true}}
      @icon="wrench"
      @label={{if @showLabel "post.controls.admin_action"}}
      @title="post.controls.admin"
    />
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
