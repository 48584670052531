import { template as template_c909565b50c245749a8ef99236903c31 } from "@ember/template-compiler";
const SidebarSectionMessage = template_c909565b50c245749a8ef99236903c31(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
