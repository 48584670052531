import { template as template_1f3a02dfc05c477f969d5f2e1d8b828c } from "@ember/template-compiler";
const FKText = template_1f3a02dfc05c477f969d5f2e1d8b828c(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
