import { template as template_cd00cc1a98724d7388019b0624607316 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_cd00cc1a98724d7388019b0624607316(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
