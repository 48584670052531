import { template as template_01633575667d43c5a89001531af2f80d } from "@ember/template-compiler";
const FKControlMenuContainer = template_01633575667d43c5a89001531af2f80d(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
