import { template as template_a724262604a941a78efdc8409c7395cf } from "@ember/template-compiler";
const FKLabel = template_a724262604a941a78efdc8409c7395cf(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
